var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "section",
      { attrs: { id: "kb-category-search" } },
      [
        _c(
          "b-card",
          {
            staticClass: "knowledge-base-bg text-center",
            style: {
              backgroundImage:
                "url(" + require("@/assets/images/banner/banner.png") + ")"
            },
            attrs: { "no-body": "" }
          },
          [
            _c(
              "b-card-body",
              { staticClass: "card-body" },
              [
                _c("h2", { staticClass: "text-primary" }, [
                  _vm._v(" Dedicated Source Used on Website ")
                ]),
                _c("b-card-text", { staticClass: "mb-2" }, [
                  _c("span", [_vm._v("Popular searches: ")]),
                  _c("span", { staticClass: "font-weight-bolder" }, [
                    _vm._v("Sales automation, Email marketing")
                  ])
                ]),
                _c(
                  "b-form",
                  { staticClass: "kb-search-input" },
                  [
                    _c(
                      "b-input-group",
                      { staticClass: "input-group-merge" },
                      [
                        _c(
                          "b-input-group-prepend",
                          { attrs: { "is-text": "" } },
                          [
                            _c("feather-icon", {
                              attrs: { icon: "SearchIcon" }
                            })
                          ],
                          1
                        ),
                        _c("b-form-input", {
                          attrs: {
                            id: "searchbar",
                            placeholder: "Ask a question...."
                          },
                          model: {
                            value: _vm.knowledgeBaseSearchQuery,
                            callback: function($$v) {
                              _vm.knowledgeBaseSearchQuery = $$v
                            },
                            expression: "knowledgeBaseSearchQuery"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ],
      1
    ),
    _c(
      "div",
      { attrs: { id: "knowledge-base-category" } },
      [
        _c(
          "b-row",
          { staticClass: "match-height" },
          [
            _vm._l(_vm.filteredKB, function(data, index) {
              return _c(
                "b-col",
                { key: index, attrs: { md: "4", sm: "6" } },
                [
                  _c(
                    "b-card",
                    [
                      _c(
                        "h6",
                        { staticClass: "kb-title" },
                        [
                          _c("feather-icon", {
                            staticClass: "mr-50",
                            class: data.iconColor,
                            attrs: { icon: data.icon, size: "20" }
                          }),
                          _vm._v(
                            _vm._s(data.title) +
                              " (" +
                              _vm._s(data.questions.length) +
                              ") "
                          )
                        ],
                        1
                      ),
                      _c(
                        "b-list-group",
                        { staticClass: "list-group-circle mt-2" },
                        _vm._l(data.questions, function(que) {
                          return _c(
                            "b-list-group-item",
                            {
                              key: que.question,
                              staticClass: "text-body",
                              attrs: {
                                to: {
                                  name: "pages-knowledge-base-question",
                                  params: {
                                    category: _vm.$route.params.category,
                                    slug: que.slug
                                  }
                                }
                              }
                            },
                            [_vm._v(" " + _vm._s(que.question) + " ")]
                          )
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            }),
            _c(
              "b-col",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.filteredKB.length,
                    expression: "!filteredKB.length"
                  }
                ],
                staticClass: "text-center",
                attrs: { cols: "12" }
              },
              [
                _c("h4", { staticClass: "mt-4" }, [
                  _vm._v(" Search result not found!! ")
                ])
              ]
            )
          ],
          2
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }